import React, { Suspense } from 'react';
import { server } from './config.js'
const Website = React.lazy(() => import('./website/website.js'));
const About = React.lazy(() => import('./website/about.js'));
const Gameplay = React.lazy(() => import('./website/gameplay.js'));
const Course = React.lazy(() => import('./course.js'));

class Router extends React.Component {

  constructor(props) {
    console.log("constructor")
    super(props)
    this.state={"page": ""}
  }

  static getDerivedStateFromProps(props, state) {
    console.log("getDerivedState")
    return state
  }

  routeMatch = (url, regexp, title, canonicalUrl, s) => {
    if (url.match(regexp)) {
      document.title=title
      var canonical = document.querySelector('link[rel="canonical"]');
      if (canonical !== null) canonical.href = canonicalUrl;
      this.setState(s)
      return true
    } else {
      return false
    }
  }

  // 1) sale_id from url param
  // 2) USER_ID from localStorage
  // 3) Generate one and save it in localStorage (USER_ID)
  getUserId = (url) => {
    function genUserId() { return "generated-" + parseInt(Math.random() * 1000000000000) }

    let regex = new RegExp("sale_id=(.+?)#?&", "i")
    let res = (url+"&").match(regex)

    if (res) {
      return res[1]

    } else if (localStorage.getItem("USER_ID")) {
      return localStorage.getItem("USER_ID")

    } else {
      localStorage.setItem("USER_ID", genUserId())
      return localStorage.getItem("USER_ID")
    }

  }

  componentDidMount() {
    console.log("didMount")
    if (this.routeMatch(
        window.location.href,
        new RegExp("/learn/en#?(.*)?$", "i"),
        "Adventure of Roby",
        "https://app.adventureofroby.com/learn",
        {"page" : "CourseLight", "lang" : "en"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/learn/fr#?(.*)?$", "i"),
        "Adventure of Roby",
        "https://app.adventureofroby.com/learn",
        {"page" : "CourseLight", "lang" : "fr"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/advanced/en#?(.*)?$", "i"),
        "Adventure of Roby",
        "https://app.adventureofroby.com/advanced",
        {"page" : "CourseAdvanced", "lang" : "en"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/advanced/fr#?(.*)?$", "i"),
        "Adventure of Roby",
        "https://app.adventureofroby.com/advanced",
        {"page" : "CourseAdvanced", "lang" : "fr"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/itch.io$", "i"),
        "Adventure of Roby",
        "https://app.adventureofroby.com/itch",
        {"page" : "Itch", "lang" : "en"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/about#?(.*)?$", "i"),
        "Adventure of Roby | About this game",
        "https://www.adventureofroby.com/about",
        {"page" : "About"}
      )) return
    else if (this.routeMatch(
        window.location.href,
        new RegExp("/gameplay#?(.*)?$", "i"),
        "Adventure of Roby | Gameplay",
        "https://www.adventureofroby.com/gameplay",
        {"page" : "Gameplay"}
      )) return
    else {
      document.title="Adventure of Roby | A coding game for everyone"
      document.querySelector('link[rel="canonical"]').href = "https://www.adventureofroby.com"
      this.setState({"page" : "Website"})
    }
  }

  render() {
    if (this.state.page == "Website") { return <div><Suspense fallback={<div></div>}><Website/></Suspense></div> }
    else if (this.state.page == "About") { return <div><Suspense fallback={<div></div>}><About/></Suspense></div> }
    else if (this.state.page == "Gameplay") { return <div><Suspense fallback={<div></div>}><Gameplay/></Suspense></div> }
    else if (this.state.page == "Itch") {
      let userId = this.getUserId(decodeURIComponent(window.location.href))
      let isDemo = false
      return <div><Suspense fallback={<div></div>}><Course lang={this.state.lang} isDemo={isDemo} userId={userId} withInfo={false} withFeedback={false}/></Suspense></div>
    }
    else if (this.state.page == "CourseLight") {
      let userId = this.getUserId(decodeURIComponent(window.location.href))
      let isDemo = false
      return <div><Suspense fallback={<div></div>}><Course lang={this.state.lang} isDemo={isDemo} userId={userId} withInfo={false} withFeedback={false}/></Suspense></div>
    }
    else if (this.state.page == "CourseAdvanced") {
      let userId = this.getUserId(decodeURIComponent(window.location.href))
      let isDemo = false
      return <div><Suspense fallback={<div></div>}><Course lang={this.state.lang} isDemo={isDemo} userId={userId} withInfo={false} withFeedback={false}/></Suspense></div>
    }
    else return <div></div>
  }

}
export default Router;
